import { spaceDivision } from '@/utils/utils';
import s from './Input.module.scss'
import { ChangeEvent, FC, HTMLInputTypeAttribute } from 'react'

type InputModeType =
    | "none"
    | "text"
    | "tel" 
    | "url" 
    | "email"
    | "numeric"
    | "decimal"
    | "search";

interface IInput {
    type?: HTMLInputTypeAttribute
    placeholder?: string | undefined
    value: string
    name?: string
    label?: string
    pattern?: string
    inputMode?: InputModeType
    onChange?: (value: string) => void
    min?: number
    max?: number
    selectName?: string
}

const Input: FC<IInput> = ({type = "text", placeholder, value, name, label, pattern, inputMode, onChange, min, max, selectName}) => {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            if (inputMode === "decimal") {
                onChange(e.target.value.replace(/[^0-9.]/g, ''));
            } else if (name === "telegram") {
                onChange(e.target.value.replace(/[^A-Za-z0-9_@]/g, ''));
            } else {
                onChange(e.target.value);
            }
        }
    }

    const rename = () => {
        if (selectName) {
            if (selectName.split(" ").length > 1 && !selectName.includes("Наличные"))
                return selectName.split(" ")[0]
            return selectName.replace("CASH", "Нал. ") 
        }
        
    }

    return (
        <div className={label ? s.exchangeInput : s.feedbackInput}>
            {
                label &&
                <div>
                    <label htmlFor={name}>{label}</label>
                    {/* {min && max && <span>Лимиты: {spaceDivision(min)} {rename()} - {spaceDivision(max)} {rename()}</span>} */}
                </div>
            }
            <input 
                placeholder={placeholder}
                value={value} 
                onChange={handleChange}
                type={type} 
                name={name} 
                id={name}
                pattern={pattern}
                inputMode={inputMode}
            />
        </div>
    )
}

export default Input
