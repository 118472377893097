const coinsCode = [
    "ETH",
    "USDTERC",
    "USDTTRC",
    "BTC",
]


export const isCoin = (label: string) => {
    if (coinsCode.filter((coin: string) => coin === label)[0]) {
        return true
    }

    return false
}