import { FC } from "react"
import s from "./SwapButton.module.scss"
import SwapArrowsSVG from "@/svg/SwapArrowsSVG"

interface ISwapButton {
    onClick?: () => void
}

const SwapButton: FC<ISwapButton> = ({onClick}) => {
    return (
        <button type="button" onClick={onClick} className={s.button}>
            <SwapArrowsSVG/>
        </button>
    )
}

export default SwapButton