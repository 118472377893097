function LocationSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_202_18)">
        <path
          d="M12.4982 12.0398C14.6863 12.0398 16.4661 10.2601 16.4661 8.0716C16.4661 5.88312 14.6863 4.10372 12.4982 4.10372C10.3101 4.10372 8.52993 5.88349 8.52993 8.0716C8.52993 10.2597 10.3097 12.0398 12.4982 12.0398ZM12.4982 5.15372C14.107 5.15372 15.4161 6.46282 15.4161 8.0716C15.4161 9.6804 14.1069 10.9898 12.4982 10.9898C10.8894 10.9898 9.57993 9.68074 9.57993 8.0716C9.57993 6.46246 10.889 5.15372 12.4982 5.15372Z"
          fill="#1D8EFF"
          stroke="#1D8EFF"
          strokeWidth="0.3"
        />
        <path
          d="M12.1346 20.4054L12.1347 20.4055C12.2364 20.5041 12.3684 20.5535 12.5 20.5535C12.6316 20.5535 12.7636 20.5041 12.8654 20.4055L12.8655 20.4054C14.0885 19.2187 15.4573 17.4863 16.6278 15.6104C17.7976 13.7355 18.7759 11.7067 19.2098 9.92627L19.2099 9.92586C19.3199 9.46883 19.4694 8.77087 19.4694 8.06938C19.4694 4.22643 16.343 1.09963 12.5 1.09963C8.65706 1.09963 5.53066 4.22643 5.53066 8.06976C5.53066 8.77125 5.68019 9.46921 5.79019 9.92694L5.79031 9.92741C6.22417 11.7073 7.20236 13.7357 8.37219 15.6104C9.54257 17.4861 10.9113 19.2185 12.1346 20.4054ZM12.5 2.15001C15.7637 2.15001 18.4194 4.80572 18.4194 8.06976C18.4194 8.65384 18.2893 9.26433 18.1892 9.67923C17.4151 12.8558 14.7644 16.938 12.4997 19.2851C10.2353 16.938 7.5846 12.8558 6.81046 9.67999C6.71036 9.26427 6.58029 8.65382 6.58029 8.06976C6.58029 4.80573 9.23638 2.15001 12.5 2.15001Z"
          fill="#1D8EFF"
          stroke="#1D8EFF"
          strokeWidth="0.3"
        />
        <path
          d="M16.4745 17.8319L16.4745 17.8319C17.2059 18.1124 17.7807 18.4613 18.1702 18.8397C18.56 19.2183 18.755 19.6163 18.755 20.0004C18.755 20.359 18.5937 20.7151 18.2821 21.0535C17.9699 21.3924 17.5128 21.7063 16.9408 21.9743C15.7969 22.5101 14.2181 22.8504 12.5 22.8504C10.7819 22.8504 9.20307 22.5101 8.05919 21.9743C7.48711 21.7063 7.03001 21.3924 6.71787 21.0535C6.40625 20.7151 6.24497 20.359 6.24497 20.0004C6.24497 19.6163 6.43992 19.2183 6.82972 18.8397C7.21923 18.4613 7.79408 18.1124 8.52543 17.8319L8.52546 17.8319C8.79642 17.7279 8.93116 17.4245 8.82785 17.1539C8.72437 16.8828 8.41962 16.7474 8.14953 16.8513C6.31461 17.5547 5.19497 18.6888 5.19497 20C5.19497 21.1336 6.04062 22.111 7.34829 22.7963C8.66161 23.4847 10.4754 23.9 12.5 23.9C14.5245 23.9 16.3383 23.4847 17.6517 22.7963C18.9593 22.111 19.805 21.1336 19.805 20C19.805 18.6888 18.6853 17.5547 16.8504 16.8513C16.5794 16.7474 16.276 16.8829 16.1722 17.1536C16.0682 17.4246 16.2038 17.728 16.4745 17.8319Z"
          fill="#1D8EFF"
          stroke="#1D8EFF"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_18">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LocationSVG;
