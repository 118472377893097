import React from "react";

function ArrowDownSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M11.6464 12.9436L12 13.2971L12.3536 12.9436L16.2339 9.06316C16.4294 8.86767 16.7474 8.86811 16.9427 9.06481C17.137 9.26053 17.1364 9.57654 16.9418 9.77106L17.2786 10.1078L16.9418 9.77106L12.3536 14.3593C12.1583 14.5546 11.8417 14.5546 11.6464 14.3593L7.05817 9.77106C6.86365 9.57654 6.86297 9.26053 7.05731 9.06481C7.25262 8.86811 7.57056 8.86767 7.76606 9.06316L11.6464 12.9436Z"
        fill="#121213"
        stroke="#121213"
      />
    </svg>
  );
}

export default ArrowDownSVG;
