import { API } from "./api"
import { IBid, ICourse, IReview } from "./interfaces"

export const fetchReviews = async (data: IReview): Promise<any> => {
  return await API.get(
    `/review/page?page=${data?.page}&reverse_order=${data?.reverse_order}`,
  )
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}

export const fetchCities = async (): Promise<any> => {
  return await API.get(`/static/cities`)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}

export const fetchDirections = async (city: string): Promise<any> => {
  return await API.get(`/static/directions?city_code=${city}`)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}

export const fetchCourses = async (data: ICourse): Promise<any> => {
  return await API.post(`/currency/course`, data)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}

export const createBid = async (data: IBid): Promise<any> => {
  return await API.post(`/order/create`, data)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}

export const fetchBid = async (id: string): Promise<any> => {
  return await API.get(`/order/get`, { headers: { uuid: id } })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
}
