import React from "react";

function SwapArrowSVG() {
  return (
    <svg
      style={{ width: "22px", height: "23px" }}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M11.9166 7.83332L14.6666 5.08332M14.6666 5.08332L17.4166 7.83332M14.6666 5.08332V17.9167M10.0833 15.1667L7.33325 17.9167M7.33325 17.9167L4.58325 15.1667M7.33325 17.9167L7.33325 5.08332"
        stroke="#1D8EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SwapArrowSVG;
