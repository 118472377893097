import { FC, useEffect, useState } from "react";
import s from "./Select.module.scss";
import cn from "classnames";
import ArrowDownSVG from "@/svg/ArrowDownSVG";
import LocationSVG from "@/svg/LocationSVG";
import classNames from "classnames";

interface IOption {
  label: string;
  code: string;
}

interface ISelect {
  options: IOption[];
  setValue: (value: string) => void;
  value: string;
  type?: string;
  defaultValue: IOption;
}

const Select: FC<ISelect> = ({
  options,
  type = "coin",
  defaultValue,
  setValue,
  value,
}) => {
  const [isActive, setIsActive] = useState(false);

  const changeValue = (option: IOption) => {
    setValue(option.code);
    setIsActive(false);
    setOptionValue({ label: option.label, code: option.code });
  };

  const [optionValue, setOptionValue] = useState<IOption>(defaultValue);

  useEffect(() => {
    if (options) {
      const temp = options?.find((option) => option?.code === value);
      setOptionValue({ label: temp?.label || "", code: temp?.code || "" });
    }
  }, [value, options]);

  if (type === "city") {
    return (
      <div className={s.citySelector} onClick={() => setIsActive(!isActive)}>
        <LocationSVG />

        <p>{optionValue.label}</p>
        <div
          style={isActive ? { display: "grid" } : { display: "none" }}
          className={classNames(s.list, s.city)}
        >
          {/* <ul> */}
          {options?.map((option) => (
            <li
              className={option.code === value ? s.activeCity : ""}
              onClick={() => changeValue(option)}
              key={option.code}
            >
              <div className={s.locationSvg}>
                <LocationSVG />
              </div>

              <span>
                {option.label.split(" ").length > 1 &&
                !option.label.includes("Наличные")
                  ? option.label.split(" ")[0]
                  : option.label.replace("Наличные", "Нал.")}
              </span>
            </li>
          ))}
          {/* </ul> */}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(s.block, s[type])}>
      <div className={cn(s.select, s[type], isActive ? s.focus : undefined)}>
        <div onClick={() => setIsActive(!isActive)} className={s.view}>
          {type == "coin" && <img src={`coins/${value}.svg`} alt="coin" />}
          <span style={{ color: "black" }}>
            {optionValue.label.split(" ").length > 1 &&
            !optionValue.label.includes("Наличные")
              ? optionValue.label.split(" ")[0]
              : optionValue.label.replace("Наличные", "Нал.")}
          </span>
          <ArrowDownSVG />
        </div>
        <div
          style={isActive ? { display: "block" } : { display: "none" }}
          className={s.list}
        >
          <ul>
            {options
              ?.filter((dir: { code: string }) => value !== dir?.code)
              ?.map((option) => (
                <li onClick={() => changeValue(option)} key={option.code}>
                  {type == "coin" && (
                    <img src={`coins/${option.code}.svg`} alt="coin" />
                  )}
                  <span>
                    {option.label.split(" ").length > 1 &&
                    !option.label.includes("Наличные")
                      ? option.label.split(" ")[0]
                      : option.label.replace("Наличные", "Нал.")}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Select;
