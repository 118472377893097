// @ts-nocheck - Roistat Metrika

export const roistatTelegramGoal = () => {
  window?.roistat?.event?.send("tg_decenter");
};

export const roistatTelegramChat = () => {
  window?.roistat?.event?.send("chat_decenter");
};

export const roistatOrder = () => {
    window?.roistat?.event?.send("order_decenter");
  };
  