import React, { FC } from "react";
import s from "./Button.module.scss";
import classNames from "classnames";

interface Props {
  children?: React.ReactNode;
  variant?: "outline" | "filled" | "blur";
  height?: number;
  onClick?: any;
}

const Button: FC<Props> = ({
  children,
  variant = "filled",
  height,
  onClick
}) => {
  return (
    <button
      style={height ? { height: height } : { height: 54 }}
      className={classNames(s.root, s[variant])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
